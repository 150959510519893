function loopDetails(fieldData, apiData) {
    Object.keys(fieldData).forEach((element) => {
        fieldData[element].value = apiData[element] !== null || apiData[element] !== "" || apiData != undefined ? apiData[element] : '-';
    });
}


function badgeColor(type, status) {
    if (status === null) 
    return;
    if (type.toLowerCase() === "tenancy") {
        switch(status.toLowerCase()) {
            case 'draft': 
                return "badge-dark";
            case 'payment_processing': 
                return "badge-primary";
            case 'pending_payment': 
                return "badge-secondary";
            case 'pending_signature': 
                return "badge-warning";
            case 'pending_landlord_signature': 
                return "badge-info";
            case 'pending_tenant_signature': 
                return "badge-light";
            case 'pending_submit': 
                return "badge-orange";
            case 'pending_stamping': 
                return "badge-pink";
            case 'completed': 
                return "badge-success";
            case 'completed_without_stamping': 
                return "badge-success";
            case 'expired': 
                return "badge-danger";
        }
    } else {
        switch(status.toLowerCase()) {
            case 'pending': 
                return "badge-primary";
            case 'delivered': 
                return "badge-success";
            case 'pending_processing': 
                return "badge-danger";
            case 'payment_processing': 
                return "badge-danger";
        }
    }
}

function statusLabel(status) {
    if (status === null) 
    return;

    let defaultTxt = status.replaceAll('_', ' ');
    return defaultTxt.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); 
}

export {
    loopDetails,
    badgeColor,
    statusLabel
};